import dynamic from "next/dynamic";
import React from "react";
import type { MenuBar as TypeMenuBar } from "@/components/Organisms/MenuBar";

const MenuBar = dynamic(() =>
  import("@/components/Organisms/MenuBar").then((c) => c.MenuBar as any)
) as typeof TypeMenuBar;

interface IGenericHeader {
  data: any;
  locales: any;
  currentLocale: string;
  social: any;
}

export default function GenericHeader({
  data,
  locales,
  currentLocale,
  social,
}: IGenericHeader): JSX.Element {
  return (
    <header id={data?.title} className="w-full  lg:absolute  m-auto">
      <MenuBar
        locales={locales}
        currentLocale={currentLocale}
        data={data}
        social={social}
      />
    </header>
  );
}
